<template>
    <section>
        <el-card>
            <el-row>
                <el-input class="search mb-10 mt-10 seachWidth float-right mr-3" placeholder="Search"
                    v-model="searchDocument" clearable @input="searchDocumentName"
                    prefix-icon="el-icon-search"></el-input>
            </el-row>
            <div class="sidemenu dialog-content">
                <el-scrollbar class="scrollbar-container">
                    <el-table border :data="searchDocument ? filteredDocuments : documentsData"
                        :header-row-class-name="'my-header-row'" class="tableSty" :show-header="true" size="mini">
                        <el-table-column class="labelSty" label="Document Name">
                            <template slot-scope="scope">
                                <p style="font-size: 14px" slot="reference">
                                    {{ scope.row.name }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Action" width="150">
                            <template slot-scope="scope">
                                <ul class="action-buttons">
                                    <li>
                                        <el-tooltip content="Preview" placement="bottom">
                                            <button class="btn btn-light btn-sm m-lr-1" type="button"
                                                @click="onPreview(scope.row)">
                                                <span class="text-muted">
                                                    <i class="fa fa-eye"></i>
                                                </span>
                                            </button>
                                        </el-tooltip>
                                    </li>

                                    <li>
                                        <el-tooltip content="Download File" placement="bottom">
                                            <button class="btn btn-light btn-sm m-lr-1" type="button"
                                                @click="onDownloadFile(scope.row)">
                                                <span class="text-muted" target="_blank" ref="downloadLink">
                                                    <i class="el-icon-download"></i>
                                                </span>
                                            </button>
                                        </el-tooltip>
                                    </li>
                                </ul>
                            </template>
                        </el-table-column>
                    </el-table>

                    <p v-if="searchDocument && filteredDocuments.length === 0" class="ml-3 m-lr-1 m-tb-1">
                        No documents found.
                    </p>
                    <p v-else-if="!searchDocument && documentsData.length === 0" class="ml-3 m-lr-1 m-tb-1">
                        No documents found.
                    </p>
                </el-scrollbar>
            </div>

            <span slot="footer">
                <button class="btn btn btn-outline-danger btn-sm m-lr-1" type="button"
                    @click="downloadDocuments = false">
                    <i class="fa fa-close"></i> Close
                </button>
            </span>
        </el-card>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "FilesDownloadComponent",
    data() {
        return {
            documentsData: [],
            downloadDocuments: false,
            searchDocument: '',
        }
    },
    computed:{
        ...mapGetters("templatesData", [
      "getDownloadUrl",
      "getDownloadError",
    ]),
    },
    methods: {
        downloadDocumentsPopup(row, step) {
            this.documentsData =
                row.entityData[step.template_id][step.id].files &&
                    row.entityData[step.template_id][step.id].files.length
                    ? row.entityData[step.template_id][step.id].files
                    : row.entityData[step.template_id][step.id];
            this.downloadDocuments = true;
            //this.downloadDocuments = true
        },
        searchDocumentName() {
            this.filteredDocuments = this.documentsData.filter((document) => {
                return document.name
                    .toLowerCase()
                    .includes(this.searchDocument.toLowerCase());
            });
        },
        async onPreview(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      let newWindow = open(
        this.getDownloadUrl,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () {};
    },
    async onDownloadFile(data) {
      this.loading = true;
      this.loadingText = "Downloading File....";
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
          this.loading = false;
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
      this.loading = false;
      this.loadingText = "Loading...";
    },
    }
}


</script>